.main-procard{
    border: 1px solid var(--light2);
    /* border-radius: 25px; */
    transition: 0.3s all;
    border-radius: 20px;
}

.procard{
    border-radius: 20px;
}

.main-procard:hover{
    border-color: var(--light-brown) !important;
}

.addtocart-btn{
    transition: 0.3s all;
}
.addtocart-btn:hover{
    background-color: #BF9B30 !important;
    color: white !important;
}

.procard-desc{
    height: 50px;
}

.procard-img{
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
    /* background-position: center; */
}

.water-wrap{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
}

.addtocart-btn-wrap{
    margin-bottom: -20px;
}

.price-top-line{
    width: 50px;
    height: 5px;
    margin: auto;
    background-color: white;
}

@keyframes shimmer-ani {
    0%{
        background-position: 200%;
    }100%{
        background-position: -200%;
    }
}

.shimmer-effect{
    width: 100%;
    aspect-ratio: 1/1;
    background-color: rgb(221, 221, 221);
    background: linear-gradient(-90deg, rgb(233, 233, 233), rgb(233, 233, 233) 20%, rgb(239, 239, 239) 50%);
    border-radius: 14px;
    background-size: 200% 100%;
    animation: shimmer-ani 2s infinite;
}

@media screen and (width < 800px){
    .addtocart-btn, .procard-price, .procard-name{
        font-size: 14px;
    }
    .procard-desc{
        height: 48px;
    }
    /* .procard-price{
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    } */
    /* .addtocart-btn-wrap{
        margin-top: 15px !important;
    } */
}