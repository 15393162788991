.nostars{
    width: 70px;
    text-align: center;
}

.noreviews{
    width: 90px;
}

.halfStar{
    background: linear-gradient(to right, #F2C010 0%, #333 0%, #333 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    appearance: textfield;
  -moz-appearance: textfield;
}

input[type=color]{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.category-options-wrap p:hover{
    background-color: gray;
}

.brand-drop{
    height: 40vh;
    overflow: hidden;
    overflow-y: auto;
}
.brand-drop p:hover{
    background-color: var(--light-brown) !important;
}

.image-label{
    width: 100%;
    aspect-ratio: 1/1;
    border: 2px dashed gray;
    background-color: rgb(242, 242, 242);
    border-radius: 30px;
    overflow: hidden;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.loader-wrap{
    background-color: rgb(190, 183, 163);
}

@keyframes loader-ani {
    0%{
        transform: rotate(0deg);
    }50%{
        transform: rotate(360deg);
    }100%{
        transform: rotate(0deg);
    }
}

.loader-roundedr{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 7px solid white;
    border-top: 7px solid rgb(190, 183, 163);
    animation: loader-ani 2s ease-in-out infinite;
}

@media screen and (width > 800px) {
    .rating-wrap{
        justify-content: flex-start !important;
    }
    .noreviews, .nostars{
        margin-left: 15px !important;
    }
    .upload-form-wrap{
        width: 600px !important;
        border-radius: 20px;
    }
}