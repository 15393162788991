.main-lising{
    margin-top: -40px;
}

.drop-wrap{
    position: relative;
}

.drop-options{
    position: absolute;
    display: none;
}

.price-range-input{
    position: relative;
}

.price-range-tracker{
    display: block;
    /* background: linear-gradient(to right, red 0%, red 14.36%, blue 14.36%, blue 52.8%, red 52.8%, red 100%); */
    width: 100%;
    height: 5px;
    border-radius: 5px;
}

.range-input{
    position: absolute;
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    right: 0;
}

.max-range{
    width: 90%;
}

.range-input::-webkit-slider-runnable-track{
    width: 100%;
    height: 0px;
    background-color: green;
    appearance: none;
    -webkit-appearance: none;
    /* background-color: red; */
}

.range-input::-webkit-slider-thumb{
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid black;
    z-index: 5;
    position: relative;
    margin-top: -6px;
    cursor: pointer;
}

.min-range{
    /* -webkit-appearance: none; */
    appearance: none;
    width: 100%;
}

.filter-color{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 0;
}

.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(227, 227, 150, 0.4);
    border-radius: 50%;
}

.filter-icon{
    display: none;
}

.filter-cross{
    display: none;
}

.desk-extra{
    display: none;
}

.listing-tabs{
    position: sticky;
    top: 20px;
}


.desk-filter{
    display: flex;
}
.mob-filter{
    display: none;
}

.shippig-card-icon{
    width: 50px;
    height: 50px;
}

.filter-drop{
    width: 50px;
}

.listing-tabs{
    overflow-x: auto;
}

.listing-tabs::-webkit-scrollbar{
    display: none;
}

.brand-dropdown-wrap{
    position: relative;
    width: 200px;
}

.brand-dropdown-options{
    position: absolute;
    height: 300px;
    overflow-y: auto;
    z-index: 6;
}

.loader-wrpper{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.loader-wrap{
    width: 100px;
    background: none !important;
    animation: loader-ani 2s ease infinite;
}

.brand-dropdown-options::-webkit-scrollbar{
    display: none;
}

@media screen and (width < 800px) {
    .filter-side{
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0px;
        right: 0px;
        background-color: rgb(255, 255, 255);
        width: 100%;
        height: 100%;
        z-index: 999;
    }
    .filter-icon{
        display: block;
    }
    .filter-side .extra-filter{
        display: block;
    }
    .extra-filter-out{
        display: none;
    }
    .filter-cross{
        display: flex;
    }
    .desk-extra{
        display: flex;
    }
    .desk-filter{
        display: none;
    }
    .mob-filter{
        display: flex;
    }
    .product-n-filter{
        margin-top: 0px !important;
    }
}