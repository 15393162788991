.category-card{
    width: 100%;
}

.cat-img-wrap{
    width: 80%;
    /* aspect-ratio: 1/1; */
    padding: 20px;
    border-radius: 20px;
    overflow: hidden;
}

@media screen and (width < 800px){
    .cat-img-wrap{
        padding: 10px;
    }

    .cate-name{
        font-size: 14px !important;
    }

    .cate-count{
        font-size: 12px !important;
    }
}