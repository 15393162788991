*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--body-font-regular);
}

:root{
  --light0: #fefed6;
  --light: #FFFFCC;
  --light2: #FFBF00;
  --brown: #A67C00;
  --light-brown: #BF9B30;
  --red: #DB4444;
  --dark: #333300 ;
  --insta: #e62e62;
  --whats: #63ce33;
  --youtu: #e53634;
}

ul{
  list-style: none;
}

.text-brand-insta{
  color: var(--insta) !important;
}
.bg-brand-insta{
  background-color: var(--insta) !important;
}
.text-brand-whats{
  color: var(--whats) !important;
}
.bg-brand-whats{
  background-color: var(--whats) !important;
}
.text-brand-youtu{
  color: var(--youtu) !important;
}
.bg-brand-youtu{
  background-color: var(--youtu) !important;
}

.bg-brand-light0{
  background-color: var(--light0) !important;
}
.bg-brand-light{
  background-color: var(--light) !important;
}
.text-brand-light{
  color: var(--light) !important;
}

.bg-brand-light2{
  background-color: var(--light2) !important;
}
.text-brand-light2{
  color: var(--light2) !important;
}
.border-brand-light{
  border-color: var(--light2) !important;
}

.bg-brand-green{
  background-color: var(--light-brown) !important;
}
.text-brand-green{
  color: var(--light-brown) !important;
}
.border-brand-green{
  border-color: var(--light-brown) !important;
}
.btn-brand-green{
  background-color: var(--light-brown) !important;
}
.btn-brand-green:hover{
  background-color: #bae368 !important;
}

.bg-brand-red{
  background-color: var(--red) !important;
}
.text-brand-red{
  color: var(--red) !important;
}
.border-brand-red{
  border-color: var(--red) !important;
}
.btn-brand-red{
  background-color: var(--red) !important;
  color: white;
}
.btn-brand-red:hover{
  background-color: #be2f2f !important;
  color: white;
}

.bg-brand-dark{
  background-color: var(--dark) !important;
}
.text-brand-dark{
  color: var(--dark) !important;
}
.border-brand-dark{
  border-color: var(--dark) !important;
}
.btn-brand-dark{
  background-color: var(--dark) !important;
  color: white;
}
.btn-brand-dark:hover{
  background-color: #191919 !important;
  color: white;
}

.bg-brand-brown{
  background-color: var(--brown) !important;
}
.text-brand-brown{
  color: var(--brown) !important;
}
.border-brand-light-brown{
  border-color: var(--brown) !important;
}
.btn-brand-brown{
  background-color: var(--brown) !important;
  color: white;
}
.btn-brand-brown:hover{
  background-color: #430303 !important;
  color: white;
}

.bg-brand-light-brown{
  background-color: var(--light-brown) !important;
}
.text-brand-light-brown{
  color: var(--light-brown) !important;
}
.border-brand-light-brown{
  border-color: var(--light-brown) !important;
}
.btn-brand-light-brown{
  background-color: var(--light-brown) !important;
  color: white;
}
.btn-brand-light-brown:hover{
  background-color: #430303 !important;
  color: white;
}


.overflow-hidden{
  overflow: hidden !important;
}

.w-max-content{
  width: max-content !important;
}

.rounded-brand{
  border-radius: 16px;
}

.rounded-brand-lg{
  border-radius: 20px;
}

.rounded-brand-sm{
  border-radius: 10px;
}

.cursor-pointer{
  cursor: pointer;
}

.fs-sm{
  font-size: 14px !important;
}
.fs-esm{
  font-size: 12px !important;
}

.full-overlay{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
}

.letter-spacing{
  letter-spacing: 2px;
}

.aspect-ratio{
  aspect-ratio: 1/1;
}