.nav-wraper{
    background: linear-gradient(var(--brown), var(--light2));
}

.nav-wraper{
    position: relative;
    top: 0px;
    z-index: 999;
}

.nav-img-wrap{
    width: 200px;
}

.nav-profile{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mob-nav-icon{
    display: none !important;
    z-index: 9999;
}

.nav-ham{
    width: 30px;
    height: 4px;
    border-radius: 5px;
    background-color: var(--dark);
}

.nav-ham:nth-child(1){
    width: 15px;
}

.search-bar{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.mob-nav-call-waht-wrap, .mob-nav-search{
    display: none;
}

@keyframes nav-ani {
    0%{
        transform: translateX(-100vw);
    }100%{
        transform: translateX(0vw);
    }
}

@keyframes ham2-ani {
    0%{
        transform: rotate(0deg);
    }100%{
        transform: rotate(45deg);
    }
}

@keyframes ham1-ani {
    0%{
        transform: rotate(0deg);
        width: 15px;
        margin-bottom: 0px !important;
    }100%{
        transform: rotate(-45deg);
        width: 30px;
        margin-bottom: -4px !important;
    }
}

.mob-ul{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(4px);
    z-index: 999;
    animation: 0.3s nav-ani ease-out;
}

.mob-ul ul{
    background-color: var(--light);
    width: 80%;
    height: 100%;
    animation: 0.8s nav-ani ease-out;
}

.cross-class{
    transform: rotate(-45deg);
}
.cross-class:nth-child(1){
    width: 30px;
    margin-bottom: -4px !important;
    animation: 0.5s ham1-ani ease;
}
.cross-class:nth-child(2){
    transform: rotate(45deg);
    animation: 0.5s ham2-ani ease;
}

@media screen and (width < 850px) {
    .desk-ul{
        display: none !important;
    }
    .desk-profile{
        display: none !important;
    }
    .mob-nav-icon{
        display: flex !important;
    }
    .mob-ul{
        display: block;
    }
    .nav-img-wrap{
        width: 190px;
    }
    .mob-nav-call-waht-wrap, .mob-nav-search{
        display: flex;
    }
    .nav-wraper{
        border-bottom-left-radius: 25px !important;
        border-bottom-right-radius: 25px !important;
    }
}