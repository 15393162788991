.hero-text {
    font-size: 65px;
}

.slick-dots{
    bottom: 10px !important;
    transition: 0.5s all;
}

.banner-wrap .slick-dots{
    bottom: -25px !important;
}

.brand-card{
    aspect-ratio: 2/1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mob-hero, .desk-hero{
    /* background-image: url('../../../public/assets/HERO\ BACKg.png'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.banner-div{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.mob-banner{
    display: none;
}

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    /* content: '•'; */
    text-align: center;
    display: flex;
    opacity: .25;
    border-radius: 50%;
    /* color: red; */
    /* border: 2px solid blue; */
    -webkit-font-smoothing: antialiased;
    align-items: center;
    justify-content: center;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.5s all;
}

@keyframes borderani {
    from{
        border-color: white;
    }to{
        border-color: black;
    }
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
    border: 1px solid;
    transition: 0.5s all;
    animation: borderani 1s ease;
}

@keyframes active-leftin-ani {
    0%{
        transform: translateX(-50vw);
        opacity: 0.1;
    }80%{
        transform: translateX(0px);
        opacity: 1;
    }
}

 @keyframes active-img-ani {
    0%{
        transform: scale(1.5);
    }80%{
        transform: scale(1);
    }
} 

.slick-active .col-md-6 p:nth-child(1){
    animation: active-leftin-ani 0.8s ease-out;
    /* transform: translateX(5px); */
}

.slick-active .col-md-6 p:nth-child(2){
    animation: active-leftin-ani 1s ease-out;
    /* animation-delay: 0.2s; */
}

.slick-active .col-md-6 button{
    animation: active-leftin-ani 1.3s ease-out;
    /* animation-delay: 0.2s; */
}

.slick-active .col-md-6 img{
    animation: active-img-ani 1s ease-out;
    /* transform: scale(1); */
}

.cat-tabs{
    position: relative;
}

.tab-back{
    position: absolute;
    width: 60px;
    height: 32px;
    left: 10px;
    transition: 0.2s ease-in-out all;
}

.cat-tabs p{
    transition: 0.3s ease-out all;
    position: relative;
    z-index: 4;
}

.whats-new-card-wrap{
    padding: 30px;
}

/* .arrow{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    display: block;
} */

.best-n-new{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 3/2;
    background-color: var(--light2);
    color: white;
}

.best-text-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(64, 59, 59, 0.6);
}

.service-img{
    width: max(80px, 30%);
}

.reel-card{
    aspect-ratio: 9/16;
    position: relative;
}

.reel-view{
    position: absolute;
    bottom: 5px;
    left: 10px;
}

@keyframes logo-slide-img {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(-100%);
    }
}

.insta-follow{
    font-size: 12px;
}

.follow-text{
    font-size: 10px;
}

.star-para{
    margin-top: -30px;
}

/* .slick-slide {
    margin-right: 10px;
} */

.logo-slide-wrap{
    /* margin-top: -5px; */
    animation: logo-slide-img 10s infinite linear;
    transition: 0.4s ease;
}

.logo-slide-img{
    width: 200px;
    margin-right: 10px;
}

.review-div .slick-dots{
    bottom: -15px !important;
}

.mob-tabs{
    display: none;
}

.mob-hero{
    display: none;
}

.brand-popup-wrap{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    height: 100%;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 999;
    background-color: #a67d0096;
}

.brnad-popup{
    width: min(100%, 600px);
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
}

.poptext{
    font-size: 10px !important;
}

.brnad-popup::-webkit-scrollbar{
    display: none;
}

.brand-pop-cross{
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: rgba(128, 128, 128, 0.287);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brand-tab{
    border: 1px solid var(--light2);
}

.brand-tab-arrow{
    width: 20px;
    height: 20px;
    background-color: var(--light2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

@media screen and (width < 800px) {
    .whats-new-card-wrap{
        padding: unset;
    }
    .hero-text{
        font-size: 40px;
    }
    .desk-tabs{
        display: none;
    }
    .mob-tabs{
        display: block;
    }
    .logo-slide-img{
        width: 170px;
        margin-right: 10px;
    }
    .mob-hero{
        display: block;
    }
    .desk-hero{
        display: none;
    }
    .desk-banner{
        display: none;
    }
    .mob-banner{
        display: block;
    }
    .reviewimg-wrap{
        padding: 0px 5px !important;
    }
    .hero-banner-wrap{
        display: none;
    }
}