.lead-card:nth-child(odd){
    background-color: var(--light);
}

.status-wrap{
    position: relative;
}

.hidden-state{
    position: absolute;
    z-index: 5;
    width: 80%;
}

.hidden-state p {
    font-size: 13px;
    cursor: pointer;
}

.hidden-state p:hover {
    background-color: rgb(217, 217, 217) !important;
}