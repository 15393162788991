.main-dashboard{
    min-height: 100vh;
}

.dash-nav{
    min-height: 97vh;
    display: flex;
    position: sticky;
    top: 8px;
    z-index: 55;
}

.ad-ham-wrap{
    position: fixed;
    top: 10px;
    right: 10px;
    display: none;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
}

.little-one{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: var(--dark);
}

.big-one{
    width: 20px;
    height: 7px;
    margin-left: 2px;
    border-radius: 5px;
    background-color: var(--dark);
}

.large-one{
    width: 37px;
    height: 7px;
    margin-top: 4px;
    border-radius: 5px;
    background-color: var(--dark);
}

.login-form-wrap{
    min-height: 100vh;
    width: 100%;
}

.ad-form-wrap{
    width: min(100%, 500px)
}

@media screen and (width < 800px) {
    .dash-nav{
        display: none;
        width: 60%;
        position: fixed;
        top: 10px;
        left: 0px;
    }
    .ad-ham-wrap{
        display: flex;
    }
}