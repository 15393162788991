.main-product-card{
    width: 100%;
}

.product-card{
    width: 100%;
    aspect-ratio: 1/1.2;
    /* height: 400px; */
    background-image: url(../../../public/assets/hero-girl.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #EBE5E1;
    transition: 0.4s ease-out;
}

.tag-wrap{
    padding: 10px;
}

.more-info-div{
    height: 85%;
}

.hover-info-icon{
    width: 35px;
    height: 35px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateX(50px);
    transition: 0.3s ease-out;
}

.hover-info-icon:hover{
    color: white;
    background-color: var(--dark);
}

.quick-btn-wrap{
    width: 100%;
    transform: translateY(80px);
    transition: 0.3s ease-out;
}

.product-card:hover .hover-info-icon{
    transform: translateX(0);
}

.product-card:hover .quick-btn-wrap{
    transform: translateY(0);
}

.product-card:hover .offer-strip-wrap{
    margin-top: 20px;
}

.product-card:hover{
    background-size: cover;
}

.quick-btn-wrap button{
    width: 47%;
    background-color: white;
}

.quick-btn-wrap button:hover{
    background-color: var(--dark);
    color: white;
}

@keyframes strip-ani {
    0%{
        transform: translateX(0%);
    }100%{
        transform: translateX(-100%);
    }
}

.offer-strip-wrap{
    margin-top: -11px;
    animation: strip-ani 17s infinite linear;
    transition: 0.4s ease;
}

.strip-para{
    font-size: 14px;
    letter-spacing: 2px;
}

.pro-name{
    font-size: 15px;
}

.discount-tag{
    font-size: 12px;
}

.cut-price{
    font-size: 13px;
}

.extra-btns{
    margin-top: -30px !important;
}

.del-conf{
    width: min(100%, 400px);
}

@media screen and (width < 800px) {
    .product-card{
        aspect-ratio: 1/1.2;
    }
    .product-tag{
        font-size: 12px;
    }
    .quick-btn-wrap button{
        width: 47%;
        background-color: white;
        display: none;
    }
    .hover-info-icon{
        display: none;
    }
    .extra-btns{
        margin-top: 5px !important;
    }
}