.main-confirm{
    min-height: 90vh;
}

.conf-fiv{
    width: min(100%, 500px);
}

.check-wrap{
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}